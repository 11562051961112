<template>
	<div id="page2" ref="page2">
		<div class="layout">
			<div class="gauche">
				<div class="logo">
					<img v-if="custom_logo" :src="custom_logo.url" />
					<img v-else src="../../assets/logovertical.png" />
				</div>
				<div class="infos">
					<div class="box" @blur="handleChange" v-if="$store.state.offre_active.page2_one"
						contenteditable="true" data-field="page2_one"
						v-html="$store.state.offre_active.page2_one"></div>
					<div class="box" @blur="handleChange" v-else contenteditable="true"
						data-field="page2_one">
						<h2>{{ $store.state.textes.page2.client }}</h2>
						<h3>{{ $store.state.offre_active.client.nom }}</h3>
						<h3>{{ $store.state.offre_active.client.contact_prenom }} {{ $store.state.offre_active.client.contact_nom }} </h3>
						<div>
							<div v-html="$store.state.offre_active.client.adresse"></div>
							<div>	{{ $store.state.offre_active.client.cp }} 
								{{$store.state.offre_active.client.ville }}
								<br />{{ $store.state.offre_active.client.pays }}
								<br />-
							</div>
							Tel. {{ $store.state.offre_active.client.contact_telephone }}
							<span v-if="$store.state.offre_active.client.contact_mobile"><br />Mob. {{
								$store.state.offre_active.client.contact_mobile }}</span>
							<br />
							Email. {{ $store.state.offre_active.client.contact_email }}
						</div>
					</div>

					<div class="box">
						<h2>{{ $store.state.textes.page2.copie }}</h2>
						<div @blur="handleChange" v-if="$store.state.offre_active.page2_two"
							class="editable" contenteditable="true" data-field="page2_two"
							v-html="$store.state.offre_active.page2_two"></div>
						<div @blur="handleChange" v-else class="editable" contenteditable="true"
							data-field="page2_two"
							v-html="$store.state.offre_active.page2_contact_commercial"></div>
					</div>

					<div class="box" @blur="handleChange" v-if="$store.state.offre_active.page2_three"
						contenteditable="true" data-field="page2_three"
						v-html="$store.state.offre_active.page2_three"></div>
					<div class="box" @blur="handleChange" v-else contenteditable="true"
						data-field="page2_three">
						<h2>{{ $store.state.textes.page2.demande }}</h2>
						<div class="brief" v-html="$store.state.offre_active.page2_demande"></div>
					</div>
				</div>
			</div>
			<div class="droite">
				<h1>
					<span>{{ $store.state.textes.page2.french }}</span>
					<span>{{ $store.state.textes.page2.roll }}</span>
					<span>{{ $store.state.textes.page2.since }}</span>
				</h1>
				<hr />
				<div class="letter">
					<div class="date" v-if="$store.state.offre_active.langue == 'fr'">
						Le {{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'en'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'us'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'ro'">
						{{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'de'">
						{{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>
					<div class="date" v-if="$store.state.offre_active.langue == 'pl'">
						{{ $store.state.offre_active.date_modifiee | moment("D/MM/Y") }}
					</div>
					<div @blur="handleChange" v-if="$store.state.offre_active.page2_four_debut"
						contenteditable="true" data-field="page2_four_debut"
						v-html="$store.state.offre_active.page2_four_debut" />
					<div @blur="handleChange" v-else contenteditable="true" data-field="page2_four_debut">
						<div class="dear">{{ $store.state.textes.page2.dear }} {{
							$store.state.offre_active.client.contact_nom }}</div>
						<div class="intro">
							{{ $store.state.textes.page2.further }}
							{{ $store.state.textes.page2.following }}
						</div>
					</div>

					<div @blur="handleChange"
						v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
						contenteditable="true" data-field="page2_intitule"
						v-html="$store.state.offre_active.page2_intitule" />
					<div @blur="handleChange" v-else contenteditable="true" data-field="page2_intitule">
						<div class="lamachine">
							<span v-if="$store.state.offre_active.langue == 'fr'">{{
								$store.state.offre_active.machine.display_name_fr }}</span>
							<span v-if="$store.state.offre_active.langue == 'en'">{{
								$store.state.offre_active.machine.display_name_en }}</span>
							<span v-if="$store.state.offre_active.langue == 'de'">{{
								$store.state.offre_active.machine.display_name_de }}</span>
							<span v-if="$store.state.offre_active.langue == 'us'">{{
								$store.state.offre_active.machine.display_name_us }}</span>
							<span v-if="$store.state.offre_active.langue == 'ro'">{{
								$store.state.offre_active.machine.display_name_ro }}</span>
							<em v-if="$store.state.offre_active.langue == 'de'"><br />TYP {{
								$store.state.offre_active.machine.name }}</em>
							<em v-else><br />TYPE {{ $store.state.offre_active.machine.name }}</em>
						</div>
					</div>

					<div @blur="handleChange" v-if="$store.state.offre_active.page2_four_fin"
						contenteditable="true" data-field="page2_four_fin"
						v-html="$store.state.offre_active.page2_four_fin" />
					<div @blur="handleChange" v-else contenteditable="true" data-field="page2_four_fin">
						<p class="respect">{{ $store.state.textes.page2.respect }}</p>
						<p class="hope">{{ $store.state.textes.page2.hope }}</p>
						<p class="yours">{{ $store.state.textes.page2.yours }}</p>
						<p class="signature">
							<strong>{{ nom_commercial | capitalize }}</strong>
							<br />
							<span v-if="nom_societe">{{ nom_societe }}</span>
							<span v-else>AMB PICOT</span>
						</p>
					</div>
				</div>
				<div class="logo-pied-de-page">
					<img src="../../assets/logofooter.png" />
				</div>
				<PiedDePage :numero="$store.state.ordre_des_pages.page2.count" />
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
import _ from "underscore";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appsk0eykwa6zk25e");

export default {
	name: "page2",
	components: {},
	data() {
		return {
			logo: "",
			order: 1,
		};
	},
	computed: {
		nom_commercial() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].display_name || "Commercial";
		},
		nom_societe() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].custom_fields?.societe || "Société";
		},
		custom_logo() {
			return this.$store.state.commerciaux.filter(el => el.ID == this.$store.state.offre_active.id_commercial)[0].custom_fields?.logo_pour_les_offres;
		},
	},
	methods: {
		changeCustomLogo(val) {
			this.logo = val;
			this.$store.commit("setActiveElement", {
				field: "custom_logo",
				value: val,
			});
		},
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.commit("setActiveElement", {
					field: e.currentTarget.dataset.field,
					value: e.currentTarget.innerHTML,
				});

				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
	},
	mounted() {
		if (this.$store.state.offre_active.langue == "fr") {
			this.$moment.locale("fr");
		} else if (this.$store.state.offre_active.langue == "de") {
			this.$moment.locale("de");
		} else if (this.$store.state.offre_active.langue == "en") {
			this.$moment.locale("en");
		} else if (this.$store.state.offre_active.langue == "pl") {
			this.$moment.locale("pl");
		}
		const ownerID = this.$store.state.offre_active.id_commercial;
		const commerciaux = this.$store.state.commerciaux;
		const lecommercial = _.find(commerciaux, (el) => {
			return el.ID == ownerID;
		});
		if (lecommercial.custom_fields.logo_pour_les_offres) {
			base("offres").update(
				[
					{
						id: this.$route.params.id,
						fields: {
							custom_logo: [
								{
									url: lecommercial.custom_fields.logo_pour_les_offres.url,
								},
							],
						},
					},
				],
				(err, records) => {
					if (err) {
						console.log(err);
						return;
					}

					records.forEach((record) => {
						setTimeout(() => {
							base("offres").find(record.id, (err, record2) => {
								if (err) {
									console.error(err);
									return;
								}
								this.changeCustomLogo(record2.fields.custom_logo);
							});
						}, 4000);
					});
				}
			);
		}
	},
};
</script>

<style lang="less">
#page2 .layout {
	display: flex;
	word-break: break-word;
	.gauche {
		flex: 0 0 300px;
		.logo {
			transform: translateX(0px);
			margin-bottom: 230px;
			img {
				max-width: 220px;
				height: auto;
			}
		}
		.box {
			padding-left: 15px;
			line-height: 20px;
			margin-bottom: 50px;
			font-size: 14px;
			color: lighten(black, 40%);
			word-break: break-all;
			h2 {
				color: #f18b00;
				font-size: 18px;
				margin: 0;
				line-height: 20px;
				padding: 0 0 5px;
			}
			h3 {
				color: #000;
				line-height: 16px;
				margin: 0;
				padding: 0 0 3px;
				text-transform: uppercase;
				font-weight: bold;
			}
			p {
				margin: 0;
			}
			.brief {
				strong {
					color: black;
				}
			}
		}
	}
	.droite {
		flex: 1;
		padding-left: 80px;
		h1 {
			font-size: 60px;
			line-height: 70px;
			color: lighten(black, 60%);
			font-weight: 300;
			margin: 20px 0;
			display: flex;
			flex-direction: column;
			span:first-child {
				color: #f18b00;
			}
		}
		hr {
			width: 60px;
			height: 10px;
			background: #f18b00;
			border: none;
			margin: 30px 0 140px;
			transform: translateX(-30px);
		}
		.letter {
			font-size: 18px;
			line-height: 24px;
			font-weight: 300;
			padding-right: 60px;
			p {
				margin-bottom: 40px;
			}
			.date {
				margin: 0 0 70px;
			}
			.dear {
				font-weight: bold;
				color: #f18b00;
				margin-bottom: 40px;
			}
			.intro {
				margin-bottom: 50px;
			}
			.lamachine {
				font-size: 30px;
				line-height: 34px;
				font-weight: bold;
				color: #f18b00;
				margin-bottom: 50px;
				span {
					color: #000;
					display: inline-block;
					padding-right: 10px;
				}
			}
			.hope {
				color: #f18b00;
			}
			.yours {
			}
			.signature {
				text-align: right;
				padding-right: 100px;
				margin-top: 100px;
			}
		}
	}
}
</style>
